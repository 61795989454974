import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Helmet } from "react-helmet"

import background from "../images/thrive-hero.jpg"
import Layout from "../components/Layout"
import "../components/Layout.css"
import Seo from "../components/Seo"

// styles
const Feature = styled.div`
  a {
    display: inline-block;
  }

  h1 {
    color: rgba(58, 196, 149, 1);
    transition: 3s .2s cubic-bezier(0.01, 0.8, 0.2, 1);

    @media (max-width:640px) {
      line-height: 1.1
    }
  }
`

const FeatureBoxWrapper = styled.div`
  overflow: hidden;
`

const FeatureBox = styled.div`
  background: url(${background});
  background-position: center;
  background-size: cover;
  height: 50vh;
  margin-top: 12px;
  border-radius: 18px;
  animation: BackgroundZoomOut 1s ease;
  transition: 3s .2s cubic-bezier(0.01, 0.8, 0.2, 1);
  overflow: hidden;
  ${'' /* transition: transform 1s; */}

  :hover {
    ${'' /* transform: scale(1.05); */}
    animation: BackgroundZoomIn 1s ease;
    overflow: hidden;

  }
`
const BackArrow = styled.div`
  width: 57px;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;

  :hover i, :hover p {
    color: rgba(60, 200, 215, 1);
  }  

  i {
    content: "\f007";
    font-family: 'Font Awesome 5 Free', 'Font Awesome 5 Brands';
    color: rgba(0, 0, 0, .78);
    font-style: normal;
  }

  p {
    font-family: 'Alpino-Bold', 'Arial', 'Helvetica', 'sans-serif';
    font-size: 19.2px;
    margin: 0;
  }

  @keyframes BackgroundZoomIn {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }

  @keyframes BackgroundZoomOut {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`

const Introduction = styled.div`
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.5%;

  a {
    font-weight: 500;
    color: rgba(58, 196, 149, 1);
  }

  a:hover {
    color: rgba(60, 200, 215, 1);
  }

  @media (max-width:640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const ProjectOverViewWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;

  @media (max-width:640px) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
`
const ProjectOverview = styled.div`
`
const ProjectDetailsWrapper = styled.div`
  grid-column-start: 3;
  grid-column-end: 3;

  @media (max-width:640px) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
`
const ProjectDetails = styled.div`
  hr {
    opacity: 0;

    @media (max-width:640px) {
      opacity: 1;
      padding-top: 24px;
    }
  }

  @media (max-width:640px) {
    margin-top: 24px;
  }
`

const ProjectDescription = styled.div`
  p {
    margin-right: 15%;

  a {
  font-weight: 500;
  color: rgba(58, 196, 149, 1);
  }

  a:hover {
    color: rgba(60, 200, 215, 1);
  }

    @media (max-width:640px) {
      margin-right: auto;
    }
  }

  img {

    @media (max-width:640px) {
      width: 100%;
    }
  }
`

const ImageDuo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.5%;

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }

  img {
    width: 100%;
  }
`

const VideoEmbed = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const ScrollOnUp = styled.div`
    display: block;

    :hover i, :hover p {
        color: rgba(60, 200, 215, 1);
    }
`

const ProjectWrapper = styled.div`
  max-width: 1344px;
  margin: 0 auto;
`

const tm = () => (
    <Layout>
      <Seo title="eCommerce Feature" />
        <Helmet>
            <script src="https://kit.fontawesome.com/077761ae24.js" crossOrigin="anonymous"></script>
        </Helmet>
        <div className="Hero">
        <div id="top"/>
        <ProjectWrapper>
            <Feature>
            <Link to="/work">
                <BackArrow>
                <i className="fas fa-long-arrow-alt-left"></i>
                <p>back</p>
                </BackArrow>
            </Link>
            <h1>Thrive Market Feature</h1>
            <FeatureBoxWrapper>
                <FeatureBox />
            </FeatureBoxWrapper>
            </Feature>
            <Introduction>
            <ProjectOverViewWrapper>
                <ProjectOverview>
                <h2>Project Overview</h2>
                <h4>As the final project of <a href="http://www.productschool.com" target="_blank" rel="noreferrer">Product School</a>, both business and product opportunities were explored with findings presented to the class for evaluation. Final projects were completed along with 40+ hours of in-class lecture, group exercises, interviews, and grooming on Product Management.</h4>
                <p><a href="https://www.thrivemarket.com" target="_blank" rel="noreferrer">Thrive Market</a> is an online retailer of healthy, non-GMO food and lifestyle products that are available on a membership-basis at discounted prices. Their humble mission statement is to “make healthy food accessible for all.”</p>
                <h3>Denouement</h3>
                <p>Since the conclusion of this project, <a href="https://www.thrivemarket.com" target="_blank" rel="noreferrer">Thrive Market</a> released a big feature to their online ordering product called “Autoship.” While “Autoship” does not include the ability to schedule when orders would be placed, its presence is affirmation that it was a worthwhile feature to develop.</p>
                </ProjectOverview>
            </ProjectOverViewWrapper>
            <ProjectDetailsWrapper>
                <ProjectDetails className="projectDetails">
                <hr></hr>
                <h5 className="details">Credits</h5>
                <p className="details">This was a solo project with feedback from 10 Product Managers in Training + 1 instructor <a href="https://www.linkedin.com/in/dalibran/" target="_blank" rel="noreferrer">Dana Alibrandi</a> while at Product School</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Client</h5>
                <p className="details"><a href="https://www.thrivemarket.com" target="_blank" rel="noreferrer">Thrive Market</a></p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">agency</h5>
                <p className="details">Independent project</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Deliverable</h5>
                <p className="details">Ten minute presentation followed by Q&A</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Goal</h5>
                <p className="details">Convince 10 colleagues that the general process and execution of a product feature was clearly understood and presented in a fashion that could be questioned and evolved into a future release.</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">RESULTS</h5>
                <p className="details">Here are the <a href="https://www.dropbox.com/s/tyypfv32n8rdl5h/peer-review-redacted.pdf?dl=0" target="_blank" rel="noreferrer">scans</a> of all feedback from the class (identities redacted)</p>
                </ProjectDetails>
                <hr></hr>
            </ProjectDetailsWrapper>
            </Introduction>
            <ProjectDescription className="projectChapter">
            <h2>Discovery</h2>
            <p>In a class with an accelerated 8-week cirriculum, the first two weeks were dedicated to self-exploration and discovery. The first step was to decide on a company with enough infrastructure to warrant proper product management within an industry that was of our choosing. Having worked in-and-around the food industry most of my professional life, I decided upon Thrive Market. I was specifically looking for a company with efforts in making social impact. Thrive Market’s mission statement, triple-bottom line ethos, and their “Thrive Gives” service made for a great qualifier.</p>
            <p>Initial user research involved mainly interviewing friends to qualify food product lines. Was this food healthy? One example, a favorite exercise supplement brand was mentioned, <a href="https://myvega.com/" target="_blank" rel="noreferrer">Vega</a>, of which Thrive Market carries in full. Other research was done on competing food and food delivery companies to get a better understanding of market positioning.</p>
            <ImageDuo>
                <StaticImage
                    src="../images/thrive-competition.jpg"
                    alt="Grid of company logos in the food industry"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                />
                <StaticImage
                    src="../images/thrive-competition-explored.jpg"
                    alt="Same grid but with categories of service labelled over the logos"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                />
            </ImageDuo>
            <div className="imgcaption">An example of research simplified, an exploration of market competition and advantages over each archetype.</div>
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Observations & Hypothesis</h2>
            <p>With a growing customer base that includes users in areas without easy access to a health food store, food co-op, or buying club, various personas were explored in similar&nbsp;locale.</p>
            <StaticImage
                src="../images/thrive-personas.jpg"
                alt="Sample draft of an early persona"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                layout="fullWidth"
            />
            <div className="imgcaption">Sample persona created for analysis and cx perspective</div>
            <p>Both a business model canvas and a value proposition canvas were created as part of a process to identify feature opportunities. A feature was discovered that would allow existing customers to schedule reoccuring orders based on a rotating calendar order date with a flexible shipping location. This feature would allow customers to help budget purchases for free shipping of staple items. It would also allow more utility, with travelling users able to ship food to various locations. The feature was titled “Schedule&nbsp;Basket."</p>
            <ImageDuo>
                <StaticImage
                    src="../images/thrive-bmc.jpg"
                    alt="Sample business model canvas for Thrive Market"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                />
                <StaticImage
                    src="../images/thrive-vpc.jpg"
                    alt="Sample value proposition canvas"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                />
            </ImageDuo>
            <div className="imgcaption">Sample business model canvas and value proposition canvas for a Thrive Market feature</div>
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Iterations</h2>
            <p>Personas were recreated at least three times to give clarity and understanding to a target customer and user. Personas were reviewed both by class instructor as well as friends for feedback.</p>
            <p>Part of the refinement process involved signing up for a trial membership, and using the existing product to search for customer experience opportunities. There existed a “shopping list” which was a dynamic list of previous orders, however setting up reorders involved a single click reorder (which did not allow customization), or a piece-meal experience of adding items into the cart while inputting correct quantities.</p>
            <p>Early feature ideas involved adding a simple wishlist, however with a mission statement to increase healthy food accessibility, and kano model comparisons, the wishlist seemed to be a bigger delighter for customers who weren’t focused on savings.</p>
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Terminus</h2>
            <h3>Demo</h3>
            <p>Using a mix of Sketch and Principle, the following feature demonstration was created to show the value of the “Schedule Basket.” In presentation, a live demo was created, below is a screen-grab video that encapsulates the idea. The feature is demonstrated when the user story flips to wireframes.</p>
            <VideoEmbed className="projectDemo">
                <iframe title="MVP screen demo" src="https://www.youtube.com/embed/nAXPm9P0mwU?rel=0" frameBorder="0" allowFullScreen></iframe>
            </VideoEmbed>
            <h3>Conclusions</h3>
            <p>My goal for this project was to incorporate learnings from Product School into a feature from research through computational design that could be presented with clarity. A go to market plan was created, and while the feature was not built and tested, the demo was well received.</p>
            <p>Thanks to Siddharth Nirmal for letting me know about Thrive Market. On a side not, I leave the class a paying member where I shop for organic staple savings while giving to a family in need. Their organic crunchy peanut butter is amahzing and rightfully, currently on back-order.</p>
            <p>If you are curious about trying out Thrive Market, then be sure to refer me as a friend here to save 15% on membership. If you are from Thrive Market, and are reading this, please, let’s grab a cup of coffee. Drop me a line.</p>
            </ProjectDescription>
            <ScrollOnUp 
            onClick={() => scrollTo('#top')}>
            <div className="ScrollToTop">
                <p>Scroll to top</p>
                <i className="fas fa-angle-up"></i>
            </div>
            </ScrollOnUp>
        </ProjectWrapper>
        </div>
    </Layout>
  )

export default tm